import type React from "react";
import { useState } from "react";

import { Search } from "lucide-react";

const SearchBar: React.FC = () => {
	const [searchTerm, setSearchTerm] = useState("");

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const searchURL = `/search.html?searchid=2701484&web=0&text=${encodeURIComponent(searchTerm)}`;

		window.location.href = searchURL;
	};

	return (
		<form
			onSubmit={handleSubmit}
			className="bg-white flex items-center border-white border-2 px-4 py-3 rounded-xl w-full hover:border-accentGreen transition lg:max-h-12 lg:max-w-[393px]"
		>
			<Search className="mr-2 relative bottom-[2px] w-5 h-5" />
			<input
				type="text"
				name="text"
				placeholder="Поиск по каталогу"
				className="focus:outline-none placeholder:text-darkGray placeholder:text-lg w-full"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
		</form>
	);
};

export default SearchBar;
