import Link from "next/link";
import { usePathname } from "next/navigation";

import CartButton from "../CartButton";
import FavoritesButton from "../FavoritesButton";

import { NAV_MENU_ITEMS } from "@/constants";
import { cn } from "@/libs/utils";
import BurgerMenu from "@/presentation/components/BurgerMenu";
import SearchBar from "@/presentation/components/SearchBar";

const Navigation: React.FC = () => {
	const pathname = usePathname();
	return (
		<div className="flex items-center w-full my-4">
			<nav className="flex items-center gap-8 w-full">
				<ul className="flex min-w-[440px] gap-5 lg:hidden">
					{NAV_MENU_ITEMS.map((item) => {
						const isActive = pathname.includes(item.href);
						return (
							<li key={item.title} className={cn("font-medium", isActive && "text-accentGreen")}>
								<Link href={item.href}>{item.title}</Link>
							</li>
						);
					})}
				</ul>
				<div className="flex items-center gap-2 flex-1">
					<BurgerMenu />
					<SearchBar />
					<div className="flex gap-2 items-center lg:ml-auto">
						<FavoritesButton />
						<CartButton />
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Navigation;
