import { useRef, useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { useClickOutside } from "@/hooks/useClickOutside";
import { cn } from "@/libs/utils";
import { ThemeColor } from "@/types";

type SearchBarProps = {
	closeSearchBar: () => void;
	isOpen: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({ closeSearchBar, isOpen }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const ref = useRef(null);

	useClickOutside({ handler: closeSearchBar, ref });

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const searchURL = `/search?searchid=2701484&web=0&text=${encodeURIComponent(searchTerm)}`;

		window.location.href = searchURL;
	};
	return (
		<form
			ref={ref}
			className={cn(
				"z-[999] fixed w-full shadow-md top-0 left-0 h-20 flex gap-2 items-center px-4 transition duration-200 -translate-y-full bg-backgroundColor/80 backdrop-blur-sm",
				isOpen ? "translate-y-0" : ""
			)}
			onSubmit={handleSubmit}
		>
			<input
				type="text"
				placeholder="Поиск по каталогу"
				className="bg-white rounded-xl h-12 w-full px-4"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<button
				type="submit"
				name="search"
				aria-label="Поиск"
				className="h-12 min-w-[48px] rounded-xl bg-accentGreen flex items-center justify-center"
			>
				<XPlitkaIcon name="search" size={24} color={ThemeColor.white} />
			</button>
		</form>
	);
};

export default SearchBar;
