import BurgerMenu from "../../BurgerMenu";

import CartButton from "@/presentation/components/Header/components/CartButton";
import FavoritesButton from "@/presentation/components/Header/components/FavoritesButton";

import SearchButton from "./SearchButton";

type NavigationProps = {
	openSearchBar: () => void;
};

const Navigation: React.FC<NavigationProps> = ({ openSearchBar }) => {
	return (
		<div className="sm:flex items-center hidden  justify-between p-4 sticky top-0 z-50 bg-backgroundColor/80 backdrop-blur-md">
			<div className="flex items-center gap-2">
				<BurgerMenu />
				<SearchButton onClick={openSearchBar} />
			</div>
			<div className="flex items-center gap-2">
				<FavoritesButton />
				<CartButton />
			</div>
		</div>
	);
};

export default Navigation;
