import type { ManufacturerGroupResponse } from "@/data/__generated__/types/generated";

import { useEffect, useRef, useState } from "react";

import Link from "next/link";

import { generateAlphabete } from "@/helpers";
import { cn } from "@/libs/utils";

type BrandsListProps = {
	className?: string;
	hoveredLetter: null | string;
	manufacturers?: ManufacturerGroupResponse[];
};

const BrandsList: React.FC<BrandsListProps> = ({ manufacturers = [], hoveredLetter, className }) => {
	const alphabet = generateAlphabete();
	const [dropdownPosition, setDropdownPosition] = useState("left-0");
	const dropdownRef = useRef<HTMLUListElement>(null);

	useEffect(() => {
		if (dropdownRef.current) {
			const dropdownRect = dropdownRef.current.getBoundingClientRect();
			const isOffscreenRight = dropdownRect.right > window.innerWidth;

			if (isOffscreenRight) {
				setDropdownPosition("right-0");
			} else {
				setDropdownPosition("left-0");
			}
		}
	}, [hoveredLetter]);

	const findManufacturersByKey = (key: string) => {
		return manufacturers.find((group) => group.key.toLowerCase() === key.toLowerCase())?.manufacturers || [];
	};

	return (
		<div className={cn("bg-white w-full h-[56px] rounded-xl flex items-center p-2 pr-5 lg:hidden", className)}>
			<Link
				href="/manufacturers"
				className="whitespace-nowrap font-medium py-2 px-4 rounded-xl text-lg hover:bg-accentGreen hover:text-white transition border border-accentGreen"
			>
				Все бренды
			</Link>
			<ul className="flex justify-between w-full font-medium">
				{alphabet.map((letter) => {
					const manufacturersGroup = findManufacturersByKey(letter);
					return (
						<li
							key={letter}
							className="hover:text-white flex items-center hover:bg-accentGreen rounded-xl transition relative cursor-pointer group"
						>
							<Link className="hover:text-white h-full min-h-10 p-2" href={`/manufacturers/${letter}`}>
								<span className="">{letter}</span>
							</Link>
							{manufacturersGroup.length > 0 && (
								<ul
									ref={dropdownRef}
									className={`absolute top-full ${dropdownPosition} bg-white z-10 shadow-2xl w-max max-h-[400px] p-4 pl-0 rounded-2xl flex-col flex-wrap hidden group-hover:flex`}
								>
									{manufacturersGroup.map((manufacturer) => (
										<li key={manufacturer.slug} className="ml-4 mb-1">
											<Link href={`/manufacturer/${manufacturer.slug}`} className="text-primaryBlack">
												{manufacturer.title}
											</Link>
										</li>
									))}
								</ul>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default BrandsList;
