"use client";

import { useState } from "react";

import api from "@/data/api";

import BrandsList from "./components/BrandsList";
import CatalogCategories from "./components/CatalogCategories";
import HeaderContacts from "./components/Contacts";
import CurrentUser from "./components/CurrentUser";
import FixedNavbar from "./components/FixedNavbar";
import Navigation from "./components/Navigation";
import SiteMessage from "./components/SiteMessage";
import useActions from "./hooks";

const Header: React.FC = () => {
	const { data: categoriesData } = api.category.Categories();
	const { data: manufacturersData } = api.manufacturers.ManufacturersGroup();
	const [isCategoriesOpen, setCategoriesOpen] = useState(false);
	const [hoveredCategoryId, setHoveredCategoryId] = useState<null | number>(null);
	const [hoveredLetter, setHoveredLetter] = useState<null | string>(null);
	const [activeMenu, setActiveMenu] = useState<"header" | "navbar" | null>(null);

	const { handleCloseCategories, handleHoverCategory, handleOpenCategories } = useActions({
		isCategoriesOpen,
		setCategoriesOpen,
		setHoveredCategoryId,
		setHoveredLetter,
		setActiveMenu,
	});

	return (
		<header className="flex items-center w-full md:hidden flex-col relative z-20 max-w-[1232px] px-4 mx-auto">
			<CurrentUser />
			<HeaderContacts />
			<Navigation />
			<div className="flex items-center gap-4 w-full py-3 lg:hidden">
				<CatalogCategories
					categories={categoriesData?.categories}
					isCategoriesOpen={isCategoriesOpen && activeMenu === "header"}
					handleOpenCategories={() => handleOpenCategories("header")}
					handleCloseCategories={handleCloseCategories}
					handleHoverCategory={handleHoverCategory}
					hoveredCategoryId={hoveredCategoryId}
				/>
				<BrandsList manufacturers={manufacturersData?.manufacturersGroups} hoveredLetter={hoveredLetter} />
			</div>
			<SiteMessage />
			<FixedNavbar
				categories={categoriesData?.categories}
				manufacturers={manufacturersData?.manufacturersGroups}
				handleCloseCategories={handleCloseCategories}
				handleHoverCategory={handleHoverCategory}
				handleOpenCategories={() => handleOpenCategories("navbar")}
				hoveredCategoryId={hoveredCategoryId}
				hoveredLetter={hoveredLetter}
				isCategoriesOpen={isCategoriesOpen && activeMenu === "navbar"}
			/>
		</header>
	);
};

export default Header;
