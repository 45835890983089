/* eslint-disable react-hooks/exhaustive-deps */
import type { RefObject } from "react";
import { useEffect } from "react";

type Event = MouseEvent | TouchEvent;

interface UseClickOutsideProps<T extends HTMLElement = HTMLElement> {
	excludeRefs?: RefObject<HTMLElement>[];
	handler: (event: Event) => void;
	ref: RefObject<T>;
}

export const useClickOutside = <T extends HTMLElement = HTMLElement>({
	excludeRefs = [],
	handler,
	ref,
}: UseClickOutsideProps<T>) => {
	useEffect(() => {
		const listener = (event: Event) => {
			if (
				ref.current?.contains(event.target as Node) ||
				excludeRefs.some((excludeRef) => excludeRef.current?.contains(event.target as Node))
			) {
				return;
			}

			handler(event);
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler, ...excludeRefs]);
};
