'use client';

import { useEffect, useState } from 'react';

import { Menu, SendHorizontal } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { Button } from '../ui/Button';
import { Drawer, DrawerContent, DrawerTrigger } from '../ui/drawer';

import { useMediaQuery } from '@/hooks/use-media-query';

import Categories from './components/categories';
import MenuLinks from './components/menu-links';

const BurgerMenu: React.FC = () => {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const isLaptop = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    if (!isOpen) return;
    setIsOpen(false);
  }, [pathname]);

  if (!isLaptop) {
    return (
      <Button className='h-[44px] w-[64px] rounded-xl items-center justify-center hidden lg:flex'>
        <Menu />
      </Button>
    );
  }

  return (
    <Drawer direction='left' open={isOpen} onOpenChange={setIsOpen}>
      <DrawerTrigger asChild>
        <Button className='h-[44px] w-[64px] rounded-xl flex items-center justify-center'>
          <Menu />
        </Button>
      </DrawerTrigger>
      <DrawerContent className='rounded-r-2xl bg-backgroundColor'>
        <div className='h-[calc(100dvh)] pb-6 pt-4 px-4 overflow-y-auto'>
          <div className='flex items-center gap-3 mb-6'>
            <Image
              width={32}
              height={32}
              priority
              loading='eager'
              className='aspect-square'
              src='/static/images/lightSquareLogo.svg'
              alt='Square Logo'
            />
            <div className='relative top-1'>
              <Image
                width={100}
                height={12}
                priority
                loading='eager'
                src='/static/images/lightLogoText.svg'
                alt='Text Logo'
              />
              <span className='text-xs'>Магазин керамической плитки</span>
            </div>
          </div>
          <Button
            asChild
            className={
              'flex w-full justify-center items-center border border-accentGreen rounded-2xl h-12 bg- mt-2 font-medium text-primaryBlack hover:bg-neutral-100 hover:text-primaryBlack'
            }
          >
            <Link href='/manufacturers' className='text-inherit'>
              Все бренды
            </Link>
          </Button>
          <Categories />
          <MenuLinks />
          <Button
            variant={'default'}
            className='border-accentGreen w-full text-base h-10 gap-2 mt-4'
            asChild
          >
            <a
              href='whatsapp://send?phone=79260129191'
              aria-label='Whatsapp'
              className='hover:text-white'
            >
              <span>Связаться с нами</span>
              <SendHorizontal className='w-5 h-5 text-white' />
            </a>
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default BurgerMenu;
