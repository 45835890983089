"use client";

import { useEffect, useRef } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { ThemeColor } from "@/types";

import useActions from "./hooks";

const BackToTop: React.FC = () => {
	const buttonRef = useRef<HTMLDivElement>(null);
	const { addScrollListener, handleScrollToTop, removeScrollListener } = useActions({ buttonRef });

	useEffect(() => {
		addScrollListener();
		return removeScrollListener;
	}, [addScrollListener, removeScrollListener]);

	return (
		<div ref={buttonRef} className="fixed bottom-[110px] right-[5%] z-50 flex-col gap-4 w-max h-max hidden">
			<a
				className="w-12 h-12 rounded-full  items-center justify-center  bg-accentGreen shadow-md flex"
				href="https://wa.me/79260129191"
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Контакты"
			>
				<XPlitkaIcon name="whatsup" size={28} color={ThemeColor.white} />
			</a>
			<button
				onClick={handleScrollToTop}
				type="button"
				name="scrollToTop"
				aria-label="Вернуться к началу страницы"
				className="w-12 h-12 rounded-full  items-center justify-center  bg-white shadow-md flex"
			>
				<XPlitkaIcon name="arrowSmallTop" size={32} color={ThemeColor.accentGreen} />
			</button>
		</div>
	);
};

export default BackToTop;
