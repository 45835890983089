type MobileHeaderActionsArgs = {
	setContactsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileHeaderActions = ({ setSearchOpen, setContactsOpen }: MobileHeaderActionsArgs) => {
	const openSearchBar = () => {
		setSearchOpen(true);
	};

	const closeSearchBar = () => {
		setSearchOpen(false);
	};

	const openContacts = () => {
		setContactsOpen(true);
	};

	const closeContacts = () => {
		setContactsOpen(false);
	};

	return {
		openSearchBar,
		closeSearchBar,
		openContacts,
		closeContacts,
	};
};

export default MobileHeaderActions;
