"use client";

import { useState } from "react";

import Contacts from "./components/Contacts";
import LogoContacts from "./components/LogoContacts";
import Navigation from "./components/Navigation";
import SearchBar from "./components/SearchBar";
import useActions from "./hooks";

const MobileHeader: React.FC = () => {
	const [searchOpen, setSearchOpen] = useState(false);
	const [contactsOpen, setContactsOpen] = useState(false);

	const { closeSearchBar, openSearchBar, closeContacts, openContacts } = useActions({
		setSearchOpen,
		setContactsOpen,
	});

	return (
		<>
			<div className="hidden sm:flex p-4 pb-0 w-full sm:flex-col z-50 ">
				<LogoContacts openContacts={openContacts} />
			</div>
			<Navigation openSearchBar={openSearchBar} />
			<SearchBar isOpen={searchOpen} closeSearchBar={closeSearchBar} />
			<Contacts isOpen={contactsOpen} closeContacts={closeContacts} />
		</>
	);
};

export default MobileHeader;
