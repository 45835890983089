import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { cn } from "@/libs/utils";
import { ThemeColor } from "@/types";

type SearchButtonProps = React.ComponentPropsWithoutRef<"button">;

const SearchButton: React.FC<SearchButtonProps> = ({ className, ...rest }) => {
	return (
		<button
			{...rest}
			aria-label="Поиск"
			className={cn(className, "bg-white rounded-xl w-[44px] h-[44px] flex items-center justify-center")}
		>
			<XPlitkaIcon name="search" color={ThemeColor.accentGreen} size={24} />
		</button>
	);
};

export default SearchButton;
