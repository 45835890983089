import(/* webpackMode: "eager" */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Onest\",\"arguments\":[{\"adjustFontFallback\":false,\"display\":\"swap\",\"subsets\":[\"cyrillic\",\"latin\"],\"preload\":true}],\"variableName\":\"onest\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/xplit/data/www/xplit.ru/public/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/xplit/data/www/xplit.ru/public/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/xplit/data/www/xplit.ru/public/src/app/providers/ApolloProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/xplit/data/www/xplit.ru/public/src/app/providers/UserProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/xplit/data/www/xplit.ru/public/src/presentation/components/BackToTop/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/xplit/data/www/xplit.ru/public/src/presentation/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/xplit/data/www/xplit.ru/public/src/presentation/components/MobileHeader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/xplit/data/www/xplit.ru/public/src/presentation/components/Yandex/index.tsx");
